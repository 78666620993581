import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './alerts/alerts.component';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SearchAlertPipe } from '../services/searchAlert.pipe';
import { GridviewCardComponent } from './gridview-card/gridview-card.component';
import { ReportsCardComponent } from './reports-card/reports-card.component';
import { ReportModal2Component } from './report-modal2/report-modal2.component';
import { ExecutiveSummaryCardComponent } from './executive-summary-card/executive-summary-card.component';
import { VtsInfoComponent } from '../asset-info/vts-info/vts-info.component';
import { WeekOdometerComponent } from './week-odometer/week-odometer.component';
import { GridfilterComponent } from './gridfilter/gridfilter.component';
import { GridSortComponent } from './grid-sort/grid-sort.component';
import { AlertSettingsComponent } from './alert-settings/alert-settings.component';
import { FormsModule } from '@angular/forms';
import { AlertCardComponent } from './alert-card/alert-card.component';
import { SearchReportService } from '../services/search-report.service';
import { VehicleFunctionComponent } from './vehicle-function/vehicle-function.component';
import { VtslivetrackComponent } from '../livetrack/vtslivetrack/vtslivetrack.component';
import { GridCardWebsiteComponent } from './grid-card-website/grid-card-website.component'
import { VtsTrackhistoryComponent } from '../trackhistory/vts-trackhistory/vts-trackhistory.component';
import { VtsNearbyComponent } from '../nearby/vts-nearby/vts-nearby.component';
import { TemperatureSettingsComponent } from './temperature-settings/temperature-settings.component';
import { TempratureCardComponent } from './temprature-card/temprature-card.component';
import { VtsGeofenceComponent } from '../geofence/vts-geofence/vts-geofence.component';
import { OverallSettingsComponent } from './overall-settings/overall-settings.component';
import { VtsReportsComponent } from '../reports/vts-reports/vts-reports.component';
import { VtsReportFormComponent } from '../reports/vts-report-form/vts-report-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicSelectableModule } from 'ionic-selectable';
import { Routes, RouterModule } from '@angular/router';
import { OverspeedPage } from '../all-reports/overspeed/overspeed.page';
import { OverallSummaryPage } from '../all-reports/overall-summary/overall-summary.page';
import { TripSummaryPage } from '../all-reports/trip-summary/trip-summary.page';
import { ExecutiveSummaryComponent } from '../all-reports/executive-summary/executive-summary.component';
import { DriverBehaviourComponent } from '../all-reports/driver-behaviour/driver-behaviour.component';
import { StatusSummaryComponent } from '../all-reports/status-summary/status-summary.component';
import { AlertReportComponent } from '../all-reports/alert-report/alert-report.component';
import { SpeedReportComponent } from '../all-reports/speed-report/speed-report.component';
import { MovementReportComponent } from '../all-reports/movement-report/movement-report.component';
import { TempraturePage } from '../all-reports/temprature/temprature.page';
import { DoorOpenCountComponent } from '../all-reports/door-open-count/door-open-count.component'
import { DoorOpenReportComponent } from '../all-reports/door-open-report/door-open-report.component';
import { DoorSummaryReportComponent } from '../all-reports/door-summary-report/door-summary-report.component';
import { GridViewComponent } from '../all-reports/executive-summary/grid-view/grid-view.component';
import { TableViewComponent } from '../all-reports/executive-summary/table-view/table-view.component';
import { TableViewDrvComponent } from '../all-reports/driver-behaviour/table-view/table-view-drv.component';
import { GridViewDrvComponent } from '../all-reports/driver-behaviour/grid-view/grid-view-drv.component';
import { GridStatusComponent } from '../all-reports/status-summary/grid-status/grid-status.component';
import { TableStatusComponent } from '../all-reports/status-summary/table-status/table-status.component';
import { GridAlertComponent } from '../all-reports/alert-report/grid-alert/grid-alert.component';
import { TableAlertComponent } from '../all-reports/alert-report/table-alert/table-alert.component';
import { GridOverspeedComponent } from '../all-reports/overspeed/grid-overspeed/grid-overspeed.component';
import { TableOverspeedComponent } from '../all-reports/overspeed/table-overspeed/table-overspeed.component';
import { GridSpeedComponent } from '../all-reports/speed-report/grid-speed/grid-speed.component';
import { TableSpeedComponent } from '../all-reports/speed-report/table-speed/table-speed.component';
import { GridMovementComponent } from '../all-reports/movement-report/grid-movement/grid-movement.component';
import { TableMovementComponent } from '../all-reports/movement-report/table-movement/table-movement.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { TemperatureGridViewComponent } from './temperature-grid-view/temperature-grid-view.component';
import { SharedModModule } from '../shared-mod/shared-mod.module';
import { TableComponent } from './table/table.component';
import { TempratureRangeComponent } from './temprature-range/temprature-range.component';
// changes by gokul
import { FleetFormComponent } from './fleet-form/fleet-form.component';
import { ManageFleetTableComponent } from './manage-fleet-table/manage-fleet-table.component';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { OperatorComponent } from '../manage-fleet/operator/operator.component';
import { OperatorformComponent } from '../manage-fleet/operator/operatorform/operatorform.component';
import { ProfileComponent } from '../manage-fleet/profile/profile.component';
import { UsersComponent } from '../manage-fleet/users/users.component';
import { AddTicketComponent } from '../ticket/add-ticket/add-ticket.component';
import { TicketCardComponent } from '../ticket/ticket-card/ticket-card.component';
//  import {TicketConversationComponent} from '../ticket/ticket-conversation/ticket-conversation.component';
import { UserformComponent } from '../manage-fleet/users/userform/userform.component';
// import { MaintanenceFormComponent } from '../maintanence/maintanence-form/maintanence-form.component';
import { MaintanenceTableComponent } from '../maintanence/maintanence-table/maintanence-table.component';
import { MaintanenceCardComponent } from '../maintanence/maintanence-card/maintanence-card.component';
import { TicketconnectorComponent } from './ticketconnector/ticketconnector.component'
import { ModelStatusComponent } from './model-status/model-status.component';
import { LatLongMapPickerPage } from '../settings/lat-long-map-picker/lat-long-map-picker.page';
import { VtsUserDiagnosisComponent } from '../diagnosis-user/vts-user-diagnosis/vts-user-diagnosis.component';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { OperatorCardComponent } from '../manage-fleet/operator-card/operator-card.component';
import { UserCardComponent } from '../manage-fleet/user-card/user-card.component';
import { ManageFleetCardComponent } from '../components/manage-fleet-card/manage-fleet-card.component'
import { AlarmreportComponent } from '../all-reports/alarmreport/alarmreport.component';
import { AddFeatureComponent } from '../manage-fleet/add-feature/add-feature.component';
import { FileUploadModule } from 'ng2-file-upload';
import { SubscriptionAlertComponent } from '../gridview-tab/subscription-alert/subscription-alert.component';
import { NodataImageComponent } from './nodata-image/nodata-image.component';
import { SearchManageFleetsService } from '../services/search-manage-fleets.service';
import { FuelreportComponent } from '../all-reports/fuelreport/fuelreport.component';
import { ConversationTabPage } from '../ticket/conversation-tab/conversation-tab.page';
import { SensorConfigurationComponent } from './sensor-configuration/sensor-configuration.component';
import { PrimitiveMaintananceComponent } from '../all-reports/primitive-maintanance/primitive-maintanance.component';
import { WeekOdometerReportComponent } from "../all-reports/week-odometer-report/week-odometer-report.component";
import { AttendanceReportComponent } from '../all-reports/attendance-report/attendance-report.component';
import { StudentAlertReportPage } from '../all-reports/student-alert-report/student-alert-report.page'
import { AcreportComponent } from '../all-reports/acreport/acreport.component';
import { ExpenseTableComponent } from '../expense-maintenance/expense-table/expense-table.component';
import { ExpenseFormComponent } from '../expense-maintenance/expense-form/expense-form.component'
import { TripCardComponent } from '../components/trip-card/trip-card.component'
import { NewTripSummaryComponent } from '../all-reports/new-trip-summary/new-trip-summary.component'
import { VtsTripSummaryComponent } from '../trip-summary/vts-trip-summary/vts-trip-summary.component'
import { ScheduleMaintenanceComponent } from '../components/schedule-maintenance/schedule-maintenance.component';
import { StopReportComponent } from '../all-reports/stop-report/stop-report.component';
import { NoTransmissionReportComponent } from '../all-reports/no-transmission-report/no-transmission-report.component';
import { VehicleMovementReportComponent } from '../all-reports/vehicle-movement-report/vehicle-movement-report.component';
import { VtsGeofenceMapSelectedComponent } from './vts-geofence-map-selected/vts-geofence-map-selected.component';
import { GoefenceSearchPipe } from '../services/goefence-search.pipe';
import { GeofenceAssociationComponent } from '../geofence/geofence-association/geofence-association.component';
import { WorkSummaryComponent } from '../all-reports/work-summary/work-summary.component';
import { GeofenceModelPage } from '../../app/geofence/geofence-model/geofence-model.page';
import { GeofenceAssociationModelComponent } from '../geofence/geofence-association-model/geofence-association-model.component';
import { AlertConfigurationComponent } from '../alerts-tab/alert-configuration/alert-configuration.component';
import { AlertConfigurationModelComponent } from '../alerts-tab/alert-configuration-model/alert-configuration-model.component'
import { VehicleOverviewComponent } from '../mapview-tab/vehicle-overview/vehicle-overview.component';
import { PreventiveModelComponent } from '../mapview-tab/preventive-model/preventive-model.component';

import { VtsSimComponent } from '../sim/vts-sim/vts-sim.component';
import { VtsDevicesComponent } from '../device/vts-devices/vts-devices.component';
import { SimDeviceModalComponent } from './sim-device-modal/sim-device-modal.component';
import { CommonReportComponent } from '../all-reports/common-report/common-report.component';
import { AdvManageVehicleComponent } from '../manage-fleet/adv-manage-vehicle/adv-manage-vehicle.component';
import { FleetSummaryComponent } from '../all-reports/fleet-summary/fleet-summary.component';
import { GridFilterPage } from '../gridview-tab/grid-filter/grid-filter.page';
import { FuelLevelConfigurationComponent } from './fuel-level-configuration/fuel-level-configuration.component';

import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { IdleViolationReportComponent } from '../all-reports/idle-violation-report/idle-violation-report.component';
import { MaintenanceReportComponent } from '../all-reports/maintenance-report/maintenance-report.component';
import { RecurrenceComponent } from '../all-reports/recurrence/recurrence.component';
import { GaugeComponent } from './gauge/gauge.component';
import { GeneratorAlarmComponent } from '../mapview-tab/generator-alarm/generator-alarm.component';
import { EscalationComponent } from '../manage-fleet/escalation/escalation.component';
import { GroupTreeStructureComponent } from '../reports/group-tree-structure/group-tree-structure.component';
import { EmployeeNoTransmissionReportComponent } from '../all-reports/employee-no-transmission-report/employee-no-transmission-report.component';
import { EmployeeMonthlyDiscountReportComponent } from '../all-reports/employee-monthly-discount-report/employee-monthly-discount-report.component';
import { EmployeeIdlestatusComponent } from '../all-reports/employee-idlestatus/employee-idlestatus.component';
import { EmployeeDayOffReportComponent } from '../all-reports/employee-day-off-report/employee-day-off-report.component';
import { EmployeeWorkOutOffZoneReportComponent } from '../all-reports/employee-work-out-off-zone-report/employee-work-out-off-zone-report.component';
import { EmployeesComponent } from '../manage-fleet/employees/employees.component';
import { EmployeesModelComponent } from '../manage-fleet/employees/employees-model/employees-model.component';
import { ShiftDetailsComponent } from '../manage-fleet/shift-details/shift-details.component';
import { ShiftDetailsModelComponent } from '../manage-fleet/shift-details/shift-details-model/shift-details-model.component';
import { NoTransmissionReportWFTComponent } from '../all-reports/no-transmission-report-wft/no-transmission-report-wft.component';
import { MaintenanceModalComponent } from '../manage-fleet/non-moveable-maintenance/maintenance-modal/maintenance-modal.component';
import { TrackDistanceComponent } from './track-distance/track-distance.component';
import { MaintanenceFormComponent } from '../maintanence/maintanence-form/maintanence-form.component';
import { ModalPage } from '../gridview-tab/vtsgrid/modal/modal.page';
import { TicketPendingComponent } from '../ticket-management-file/ticket-pending/ticket-pending.component';
import { TicketModalComponent } from '../ticket-management-file/ticket-modal/ticket-modal.component';
import { BluetoothSensorComponent } from './bluetooth-sensor/bluetooth-sensor.component';
import { RACComponent } from '../gridview-tab/vtsgrid/rac/rac.component';
import { AlertshowComponent } from './alertshow/alertshow.component';
import { ParentAdditionalComponent } from '../skt/parent/parent-additional/parent-additional.component';
import { AddStudentComponent } from './add-student/add-student.component';
import { TagAdditionalComponent } from '../skt/tag/tag-additional/tag-additional.component';
import { RouteFormComponent } from '../skt/route/route-form/route-form.component';
import { ClassAdditionalComponent } from '../skt/classdetails/class-additional/class-additional.component';
import { GateAdditionalComponent } from '../skt/gate/gate-additional/gate-additional.component';
import { RouteAddtionalComponent } from '../skt/route/route-addtional/route-addtional.component';
import { AddAlertsComponent } from './add-alerts/add-alerts.component';
import { AddRouteComponent } from './add-route/add-route.component';
import { BusStopComponent } from '../skt/route/bus-stop/bus-stop.component';
import { LatlongComponent } from './latlong/latlong.component';
import { ContractorComponent } from './contractor/contractor.component';
import { Contractor1Component } from './contractor/contractor1/contractor1.component';
import { WmsRoutePage } from '../wms-route/wms-route.page';
import { WmsRouteFormPage } from '../wms-route-form/wms-route-form.page';
import { WmsLatlongComponent } from '../wms-latlong/wms-latlong.component';

import { AddBusStopComponent } from './add-bus-stop/add-bus-stop.component';
import { BinDetailsComponent } from './bin-details/bin-details.component';
import { AddBinComponent } from './bin-details/add-bin/add-bin.component';
import { CandataComponent } from './candata/candata.component';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/Lang/', '.json');
}


const routes: Routes = [
  {
    path: 'overspeedReport',
    component: OverspeedPage
  }, {
    path: 'overallSummaryReport',
    component: OverallSummaryPage
  }, {
    path: 'tripSummaryReport',
    component: TripSummaryPage
  },
  {
    path: 'executiveSummaryReport',
    component: ExecutiveSummaryComponent
  },
  {
    path: 'driverBehavior',
    component: DriverBehaviourComponent
  },
  {
    path: 'statusSummary',
    component: StatusSummaryComponent
  },
  {
    path: 'alertReport',
    component: AlertReportComponent
  },
  {
    path: 'speedReport',
    component: SpeedReportComponent
  },
  {
    path: 'movementReport',
    component: MovementReportComponent
  },
  {
    path: 'temprature',
    component: TempraturePage
  },
  {
    path: 'doorOpenReport',
    component: DoorOpenReportComponent
  },
  {
    path: 'doorSummaryReport',
    component: DoorSummaryReportComponent
  },
  {
    path: 'doorCountReport',
    component: DoorOpenCountComponent
  },
  {
    path: 'latlongData',
    component: LatLongMapPickerPage
  },
  {
    path: 'alarmreport',
    component: AlarmreportComponent
  },
  {
    path: 'fuelreport',
    component: FuelreportComponent
  },
  {
    path: 'primitiveMaintanance',
    component: PrimitiveMaintananceComponent
  },
  {
    path: 'weekodometerreport',
    component: WeekOdometerReportComponent
  },
  {
    path: 'attendencereport',
    component: AttendanceReportComponent
  },
  {
    path: 'studentalert',
    component: StudentAlertReportPage
  },
  {
    path: 'acreport',
    component: AcreportComponent
  },
  {
    path: 'stopReport',
    component: StopReportComponent
  },
  {
    path: 'notransmissionreport',
    component: NoTransmissionReportComponent
  },
  {
    path: 'vehicleMovementreport',
    component: VehicleMovementReportComponent
  },
  {
    path: 'workSummaryReport',
    component: WorkSummaryComponent
  },
  {
    path: 'commonReport',
    component: CommonReportComponent
  },
  {
    path: 'fleetSummary',
    component: FleetSummaryComponent
  },
  {
    path: 'idleViolation',
    component: IdleViolationReportComponent
  },
  {
    path: 'maintenance',
    component: MaintenanceReportComponent
  },
  {
    path: 'recurrence',
    component: RecurrenceComponent
  },
  {
    path: 'employeeNoTransmission',
    component: EmployeeNoTransmissionReportComponent
  },
  {
    path: 'employeeMonthlyDiscount',
    component: EmployeeMonthlyDiscountReportComponent
  }

  ,
  {
    path: 'employeeIdleStatus',
    component: EmployeeIdlestatusComponent
  },
  {
    path: 'employeeDayOffReport',
    component: EmployeeDayOffReportComponent
  },
  {
    path: 'employeeWorkOutOffZone',
    component: EmployeeWorkOutOffZoneReportComponent
  },
  {
    path: 'notransmissionreportWFT',
    component: NoTransmissionReportWFTComponent
  }

];
const gridFilterPage: Routes = [
  {
    path: '',
    component: GridFilterPage
  }
];
@NgModule({
  declarations: [WmsRoutePage, WmsRouteFormPage, WmsLatlongComponent, BinDetailsComponent, AddBinComponent, AddBusStopComponent,
    ContractorComponent, Contractor1Component,
    TripCardComponent, ConversationTabPage, AlertsComponent, GridFilterPage, NewTripSummaryComponent, StudentAlertReportPage, AcreportComponent, AttendanceReportComponent, ManageFleetCardComponent, SearchReportService, SearchManageFleetsService, SearchAlertPipe, GridviewCardComponent, ReportsCardComponent, ReportModal2Component, ExecutiveSummaryCardComponent, VtsInfoComponent, WeekOdometerComponent, GridSortComponent, AlertSettingsComponent, GridfilterComponent, AlertCardComponent, VehicleFunctionComponent, VtslivetrackComponent, VtsTrackhistoryComponent, GridCardWebsiteComponent, VtsNearbyComponent, TemperatureSettingsComponent, TempratureCardComponent, VtsGeofenceComponent, VtsGeofenceMapSelectedComponent, OverallSettingsComponent, VtsReportsComponent, VtsReportFormComponent, OverspeedPage, OverallSummaryPage,
    TripSummaryPage, VtsTripSummaryComponent, ScheduleMaintenanceComponent, AddFeatureComponent, ExecutiveSummaryComponent, GridViewComponent, TableViewComponent, MaintanenceCardComponent, LatLongMapPickerPage, PrimitiveMaintananceComponent, WeekOdometerReportComponent, NoTransmissionReportComponent,
    TripSummaryPage, ExecutiveSummaryComponent, GridViewComponent, TableViewComponent, MaintanenceCardComponent, LatLongMapPickerPage, SensorConfigurationComponent, GeofenceAssociationComponent, GeofenceAssociationModelComponent, NoTransmissionReportWFTComponent,
    DriverBehaviourComponent, TableViewDrvComponent, GridViewDrvComponent, StatusSummaryComponent, TicketconnectorComponent, AlarmreportComponent, GoefenceSearchPipe, AlertConfigurationModelComponent, VehicleOverviewComponent, PreventiveModelComponent, FuelLevelConfigurationComponent,
    GridStatusComponent, ExpenseFormComponent, TableStatusComponent, AlertReportComponent, GridAlertComponent, TableAlertComponent, TempratureRangeComponent, FuelreportComponent, VtsSimComponent, VtsDevicesComponent, RecurrenceComponent,
    GridOverspeedComponent, VtsUserDiagnosisComponent, TableOverspeedComponent, SpeedReportComponent, GridSpeedComponent, TableSpeedComponent, ModelStatusComponent, SimDeviceModalComponent, GaugeComponent, EmployeeNoTransmissionReportComponent, EmployeeMonthlyDiscountReportComponent,
    MovementReportComponent, FleetFormComponent, AdvManageVehicleComponent, ManageFleetTableComponent, jqxDropDownListComponent, GridMovementComponent, TableMovementComponent, TempraturePage, TableComponent, GeofenceModelPage, AlertConfigurationComponent, IdleViolationReportComponent, MaintenanceReportComponent, BluetoothSensorComponent,
    DoorOpenReportComponent, SubscriptionAlertComponent, OperatorCardComponent, UserCardComponent, DoorSummaryReportComponent, DoorOpenCountComponent, TemperatureGridViewComponent, MaintanenceTableComponent, WorkSummaryComponent, CommonReportComponent, FleetSummaryComponent,
    OperatorComponent, OperatorformComponent, ProfileComponent, UsersComponent, AddTicketComponent, TicketCardComponent, UserformComponent, NodataImageComponent, ExpenseTableComponent, StopReportComponent, VehicleMovementReportComponent, GeneratorAlarmComponent, EscalationComponent
    , GroupTreeStructureComponent, EmployeeIdlestatusComponent, EmployeeDayOffReportComponent, EmployeeWorkOutOffZoneReportComponent, EmployeesComponent, EmployeesModelComponent, ShiftDetailsComponent, ShiftDetailsModelComponent, MaintenanceModalComponent, TrackDistanceComponent, MaintanenceFormComponent, ModalPage, TicketPendingComponent, TicketModalComponent, RACComponent, AlertshowComponent,
    ParentAdditionalComponent, AddStudentComponent, TagAdditionalComponent, RouteFormComponent, ClassAdditionalComponent, GateAdditionalComponent, BusStopComponent, RouteAddtionalComponent, AddRouteComponent, AddAlertsComponent, LatlongComponent, CandataComponent],
  imports: [
    CommonModule, TranslateModule,
    IonicModule.forRoot(),
    RouterModule.forChild(routes),
    FormsModule, FileUploadModule,
    IonicSelectableModule, OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    SharedModModule
  ],
  entryComponents: [WmsRoutePage, WmsRouteFormPage, WmsLatlongComponent, BinDetailsComponent, AddBinComponent, AddBusStopComponent,
    ConversationTabPage, ExpenseFormComponent, FleetFormComponent, GridFilterPage, Contractor1Component,
    AdvManageVehicleComponent, SubscriptionAlertComponent, AddFeatureComponent, OperatorformComponent,
    UserformComponent, AddTicketComponent, GeofenceAssociationModelComponent,
    GeofenceModelPage, AlertConfigurationModelComponent, VehicleOverviewComponent, PreventiveModelComponent, TrackDistanceComponent,
    SimDeviceModalComponent, GroupTreeStructureComponent, EmployeesModelComponent, ShiftDetailsModelComponent, MaintenanceModalComponent, MaintanenceFormComponent, ModalPage, TicketModalComponent, RACComponent, VtsTrackhistoryComponent, AlertshowComponent,
    ParentAdditionalComponent, AddStudentComponent, TagAdditionalComponent, RouteFormComponent, ClassAdditionalComponent, GateAdditionalComponent, BusStopComponent, RouteAddtionalComponent, AddRouteComponent, AddAlertsComponent, LatlongComponent],
  exports: [WmsRoutePage, WmsRouteFormPage, WmsLatlongComponent, BinDetailsComponent, AddBinComponent, AddBusStopComponent,
    ConversationTabPage, VtsGeofenceMapSelectedComponent, GridFilterPage, TranslateModule, ContractorComponent, Contractor1Component,
    TripCardComponent, NoTransmissionReportComponent, GaugeComponent, NoTransmissionReportWFTComponent,
    AlertsComponent, VehicleMovementReportComponent, WorkSummaryComponent, CommonReportComponent,
    SensorConfigurationComponent, GeofenceAssociationComponent,
    VtsReportFormComponent, AttendanceReportComponent, TrackDistanceComponent,
    AddFeatureComponent, FuelreportComponent, RecurrenceComponent, GeneratorAlarmComponent,
    TableComponent, SearchManageFleetsService, AcreportComponent,
    VtsReportsComponent, NodataImageComponent, WeekOdometerReportComponent,
    TempratureCardComponent, StopReportComponent, EmployeeNoTransmissionReportComponent,
    LatLongMapPickerPage, ManageFleetCardComponent,
    TemperatureSettingsComponent, StudentAlertReportPage, NewTripSummaryComponent,
    AlertCardComponent, VtsUserDiagnosisComponent,
    VtsNearbyComponent, OperatorCardComponent, UserCardComponent,
    OverallSettingsComponent, EmployeeMonthlyDiscountReportComponent,
    GridCardWebsiteComponent,
    VtslivetrackComponent, EmployeeIdlestatusComponent, EmployeeDayOffReportComponent, EmployeeWorkOutOffZoneReportComponent,
    VtsTrackhistoryComponent,
    VehicleFunctionComponent, FleetSummaryComponent, IdleViolationReportComponent, MaintenanceReportComponent,
    VtsTripSummaryComponent,
    ScheduleMaintenanceComponent, GeofenceAssociationModelComponent,
    GridviewCardComponent,
    ExpenseFormComponent,
    VtsGeofenceComponent,
    ExpenseTableComponent,
    ReportsCardComponent,
    ReportModal2Component, VtsSimComponent, VtsDevicesComponent,
    SubscriptionAlertComponent, BluetoothSensorComponent,
    ExecutiveSummaryCardComponent,
    VtsInfoComponent, ModelStatusComponent, AlertConfigurationComponent,
    WeekOdometerComponent, PrimitiveMaintananceComponent,
    GridSortComponent, TicketconnectorComponent,
    SearchReportService, FuelLevelConfigurationComponent,
    AlertSettingsComponent, MaintanenceTableComponent, AlarmreportComponent,
    GridfilterComponent, OverspeedPage, OverallSummaryPage, TempratureRangeComponent,
    TripSummaryPage, ExecutiveSummaryComponent, GridViewComponent, TableViewComponent, MaintanenceCardComponent,
    DriverBehaviourComponent, TableViewDrvComponent, GridViewDrvComponent, StatusSummaryComponent,
    GridStatusComponent, TableStatusComponent, AlertReportComponent, GridAlertComponent, TableAlertComponent,
    GridOverspeedComponent, TableOverspeedComponent, SpeedReportComponent, GridSpeedComponent, TableSpeedComponent,
    MovementReportComponent, GridMovementComponent, TableMovementComponent, TempraturePage, TemperatureGridViewComponent,
    FleetFormComponent, AdvManageVehicleComponent, ManageFleetTableComponent, DoorOpenReportComponent, DoorSummaryReportComponent, DoorOpenCountComponent,
    OperatorComponent, OperatorformComponent, ProfileComponent, UsersComponent, AddTicketComponent, TicketCardComponent, UserformComponent, GeneratorAlarmComponent, EscalationComponent,
    GroupTreeStructureComponent, EmployeesComponent, EmployeesModelComponent, ShiftDetailsComponent, ShiftDetailsModelComponent, MaintenanceModalComponent, MaintanenceFormComponent, ModalPage, TicketPendingComponent, TicketModalComponent, RACComponent, AlertshowComponent
    , ParentAdditionalComponent, AddStudentComponent, TagAdditionalComponent, RouteFormComponent, ClassAdditionalComponent, GateAdditionalComponent, BusStopComponent, RouteAddtionalComponent, AddRouteComponent, AddAlertsComponent, LatlongComponent, CandataComponent
  ],
})

export class ComponentsModule { }
